import { CountryDetails } from '../shared_models/country-details';
import { StripeRegions } from '../shared_models/stripe';

export const countries: Record<string, CountryDetails> = {
    AT: {
        code: 'AT',
        country: 'Austria',
        call_code: '+43',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'de',
        alternative_language_codes: null
    },
    AU: {
        code: 'AU',
        country: 'Australia',
        call_code: '+61',
        currency: 'AUD',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.AU,
        stripe_terminal: true,
        language_code: 'en',
        alternative_language_codes: null
    },
    BE: {
        code: 'BE',
        country: 'Belgium',
        call_code: '+32',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'nl',
        alternative_language_codes: ['fr', 'de']
    },
    BG: {
        code: 'BG',
        country: 'Bulgaria',
        call_code: '+359',
        currency: 'BGN',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'bg',
        alternative_language_codes: null
    },
    // BR: {
    //     code: 'BR',
    //     country: 'Brazil',
    //     call_code: '+55',
    //     currency: 'BRL',
    //     service_agreement: 'full',
    //     capabilities: {
    //         card_payments: {requested: true },    //         transfers: { requested: true }
    //     },
    //     platform: StripeRegions.EU,
    //     stripe_terminal: false,
    //     language_code: 'pt',

    // },
    CA: {
        code: 'CA',
        country: 'Canada',
        call_code: '+1',
        currency: 'CAD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: true,
        language_code: 'en',
        alternative_language_codes: ['fr']
    },
    CY: {
        code: 'CY',
        country: 'Cyprus',
        call_code: '+357',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'el',
        alternative_language_codes: ['tr']
    },
    CZ: {
        code: 'CZ',
        country: 'Czech Republic',
        call_code: '+420',
        currency: 'CZK',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'cs',
        alternative_language_codes: null
    },
    DK: {
        code: 'DK',
        country: 'Denmark',
        call_code: '+45',
        currency: 'DKK',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'da',
        alternative_language_codes: null
    },
    EE: {
        code: 'EE',
        country: 'Estonia',
        call_code: '+372',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'et',
        alternative_language_codes: null
    },
    FI: {
        code: 'FI',
        country: 'Finland',
        call_code: '+358',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'fi',
        alternative_language_codes: ['sv']
    },
    FR: {
        code: 'FR',
        country: 'France',
        call_code: '+33',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'fr',
        alternative_language_codes: null
    },
    DE: {
        code: 'DE',
        country: 'Germany',
        call_code: '+49',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'de',
        alternative_language_codes: null
    },
    GI: {
        code: 'GI',
        country: 'Gibraltar',
        call_code: '+350',
        currency: 'GBP',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: null
    },
    GR: {
        code: 'GR',
        country: 'Greece',
        call_code: '+30',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'el',
        alternative_language_codes: null
    },
    HR: {
        code: 'HR',
        country: 'Croatia',
        call_code: '+385',
        currency: 'HRK',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'hr',
        alternative_language_codes: null
    },
    HK: {
        code: 'HK',
        country: 'Hong Kong',
        call_code: '+852',
        currency: 'HKD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'zh',
        alternative_language_codes: ['en']
    },
    HU: {
        code: 'HU',
        country: 'Hungary',
        call_code: '+36',
        currency: 'HUF',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'hu',
        alternative_language_codes: null
    },
    // IN: {
    //     code: 'IN',
    //     country: 'India',
    //     call_code: '+91',
    //     currency: 'INR',
    //     service_agreement: 'full',
    //     capabilities: {
    //         card_payments: {requested: true },    //         transfers: { requested: true }
    //     },
    //     platform: StripeRegions.EU,
    //     stripe_terminal: false,
    //     language_code: 'hi',
    //     alternative_language_codes: ['en']
    // },
    IE: {
        code: 'IE',
        country: 'Ireland',
        call_code: '+353',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'en',
        alternative_language_codes: ['ga']
    },
    IT: {
        code: 'IT',
        country: 'Italy',
        call_code: '+39',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'it',
        alternative_language_codes: null
    },
    JP: {
        code: 'JP',
        country: 'Japan',
        call_code: '+81',
        currency: 'JPY',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'ja',
        alternative_language_codes: null
    },
    LV: {
        code: 'LV',
        country: 'Latvia',
        call_code: '+371',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'lv',
        alternative_language_codes: null
    },
    LI: {
        code: 'LI',
        country: 'Liechtenstein',
        call_code: '+423',
        currency: 'CHF',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'de',
        alternative_language_codes: null
    },
    LT: {
        code: 'LT',
        country: 'Lithuania',
        call_code: '+370',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'lt',
        alternative_language_codes: null
    },
    LU: {
        code: 'LU',
        country: 'Luxembourg',
        call_code: '+352',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'lb',
        alternative_language_codes: ['fr', 'de']
    },
    MY: {
        code: 'MY',
        country: 'Malaysia',
        call_code: '+60',
        currency: 'MYR',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: true,
        language_code: 'ms',
        alternative_language_codes: ['en']
    },
    MT: {
        code: 'MT',
        country: 'Malta',
        call_code: '+356',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'mt',
        alternative_language_codes: ['en']
    },
    MX: {
        code: 'MX',
        country: 'Mexico',
        call_code: '+52',
        currency: 'MXN',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'es',
        alternative_language_codes: null
    },
    NL: {
        code: 'NL',
        country: 'Netherlands',
        call_code: '+31',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'nl',
        alternative_language_codes: null
    },
    NZ: {
        code: 'NZ',
        country: 'New Zealand',
        call_code: '+64',
        currency: 'NZD',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.NZ,
        stripe_terminal: true,
        language_code: 'en',
        alternative_language_codes: ['mi']
    },
    NO: {
        code: 'NO',
        country: 'Norway',
        call_code: '+47',
        currency: 'NOK',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'no',
        alternative_language_codes: null
    },
    PL: {
        code: 'PL',
        country: 'Poland',
        call_code: '+48',
        currency: 'PLN',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'pl',
        alternative_language_codes: null
    },
    PT: {
        code: 'PT',
        country: 'Portugal',
        call_code: '+351',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'pt',
        alternative_language_codes: null
    },
    RO: {
        code: 'RO',
        country: 'Romania',
        call_code: '+40',
        currency: 'RON',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'ro',
        alternative_language_codes: null
    },
    SG: {
        code: 'SG',
        country: 'Singapore',
        call_code: '+65',
        currency: 'SGD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: true,
        language_code: 'en',
        alternative_language_codes: ['zh', 'ta', 'ms']
    },
    SK: {
        code: 'SK',
        country: 'Slovakia',
        call_code: '+421',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'sk',
        alternative_language_codes: null
    },
    SI: {
        code: 'SI',
        country: 'Slovenia',
        call_code: '+386',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: false,
        language_code: 'sl',
        alternative_language_codes: null
    },
    ES: {
        code: 'ES',
        country: 'Spain',
        call_code: '+34',
        currency: 'EUR',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'es',
        alternative_language_codes: null
    },
    SE: {
        code: 'SE',
        country: 'Sweden',
        call_code: '+46',
        currency: 'SEK',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'sv',
        alternative_language_codes: null
    },
    CH: {
        code: 'CH',
        country: 'Switzerland',
        call_code: '+41',
        currency: 'CHF',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'de',
        alternative_language_codes: ['fr', 'it']
    },
    TH: {
        code: 'TH',
        country: 'Thailand',
        call_code: '+66',
        currency: 'THB',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'th',
        alternative_language_codes: null
    },
    AE: {
        code: 'AE',
        country: 'United Arab Emirates',
        call_code: '+971',
        currency: 'AED',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ar',
        alternative_language_codes: null
    },
    GB: {
        code: 'GB',
        country: 'United Kingdom',
        call_code: '+44',
        currency: 'GBP',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.EU,
        stripe_terminal: true,
        language_code: 'en',
        alternative_language_codes: null
    },
    US: {
        code: 'US',
        country: 'United States',
        call_code: '+1',
        currency: 'USD',
        service_agreement: 'full',
        capabilities: {
            card_payments: { requested: true },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: true,
        language_code: 'en',
        alternative_language_codes: null
    },
    DZ: {
        code: 'DZ',
        country: 'Algeria',
        call_code: '+213',
        currency: 'DZD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ar',
        alternative_language_codes: ['fr']
    },
    AO: {
        code: 'AO',
        country: 'Angola',
        call_code: '+244',
        currency: 'AOA',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'pt',
        alternative_language_codes: null
    },
    AG: {
        code: 'AG',
        country: 'Antigua and Barbuda',
        call_code: '+1-268',
        currency: 'XCD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: null
    },
    AR: {
        code: 'AR',
        country: 'Argentina',
        call_code: '+54',
        currency: 'ARS',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'es',
        alternative_language_codes: null
    },
    AM: {
        code: 'AM',
        country: 'Armenia',
        call_code: '+374',
        currency: 'AMD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'hy',
        alternative_language_codes: null
    },
    AZ: {
        code: 'AZ',
        country: 'Azerbaijan',
        call_code: '+994',
        currency: 'AZN',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'az',
        alternative_language_codes: null
    },
    BS: {
        code: 'BS',
        country: 'Bahamas',
        call_code: '+1-242',
        currency: 'BSD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: null
    },
    BH: {
        code: 'BH',
        country: 'Bahrain',
        call_code: '+973',
        currency: 'BHD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ar',
        alternative_language_codes: null
    },
    BD: {
        code: 'BD',
        country: 'Bangladesh',
        call_code: '+880',
        currency: 'BDT',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'bn',
        alternative_language_codes: null
    },
    BJ: {
        code: 'BJ',
        country: 'Benin',
        call_code: '+229',
        currency: 'XOF',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'fr',
        alternative_language_codes: null
    },
    BT: {
        code: 'BT',
        country: 'Bhutan',
        call_code: '+975',
        currency: 'BTN',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'dz',
        alternative_language_codes: null
    },
    BW: {
        code: 'BW',
        country: 'Botswana',
        call_code: '+267',
        currency: 'BWP',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: ['tn']
    },
    BI: {
        code: 'BI',
        country: 'Burundi',
        call_code: '+257',
        currency: 'BIF',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'fr',
        alternative_language_codes: null
    },
    KH: {
        code: 'KH',
        country: 'Cambodia',
        call_code: '+855',
        currency: 'KHR',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'km',
        alternative_language_codes: null
    },
    CL: {
        code: 'CL',
        country: 'Chile',
        call_code: '+56',
        currency: 'CLP',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'es',
        alternative_language_codes: null
    },
    CO: {
        code: 'CO',
        country: 'Colombia',
        call_code: '+57',
        currency: 'COP',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'es',
        alternative_language_codes: null
    },
    CR: {
        code: 'CR',
        country: 'Costa Rica',
        call_code: '+506',
        currency: 'CRC',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'es',
        alternative_language_codes: null
    },
    CI: {
        code: 'CI',
        country: 'Ivory Coast',
        call_code: '+225',
        currency: 'XOF',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'fr',
        alternative_language_codes: null
    },
    DO: {
        code: 'DO',
        country: 'Dominican Republic',
        call_code: '+1-809 and 1-829',
        currency: 'DOP',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'es',
        alternative_language_codes: null
    },
    EG: {
        code: 'EG',
        country: 'Egypt',
        call_code: '+20',
        currency: 'EGP',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ar',
        alternative_language_codes: null
    },
    SV: {
        code: 'SV',
        country: 'El Salvador',
        call_code: '+503',
        currency: 'USD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'es',
        alternative_language_codes: null
    },
    ET: {
        code: 'ET',
        country: 'Ethiopia',
        call_code: '+251',
        currency: 'ETB',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'am',
        alternative_language_codes: null
    },
    GA: {
        code: 'GA',
        country: 'Gabon',
        call_code: '+241',
        currency: 'XAF',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'fr',
        alternative_language_codes: null
    },
    GM: {
        code: 'GM',
        country: 'Gambia',
        call_code: '+220',
        currency: 'GMD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: null
    },
    GH: {
        code: 'GH',
        country: 'Ghana',
        call_code: '+233',
        currency: 'GHS',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: null
    },
    GT: {
        code: 'GT',
        country: 'Guatemala',
        call_code: '+502',
        currency: 'GTQ',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'es',
        alternative_language_codes: null
    },
    GY: {
        code: 'GY',
        country: 'Guyana',
        call_code: '+592',
        currency: 'GYD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: null
    },
    IS: {
        code: 'IS',
        country: 'Iceland',
        call_code: '+354',
        currency: 'EUR',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'is',
        alternative_language_codes: null
    },
    ID: {
        code: 'ID',
        country: 'Indonesia',
        call_code: '+62',
        currency: 'IDR',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: true,
        language_code: 'id',
        alternative_language_codes: null
    },
    IL: {
        code: 'IL',
        country: 'Israel',
        call_code: '+972',
        currency: 'ILS',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'he',
        alternative_language_codes: ['ar']
    },
    JM: {
        code: 'JM',
        country: 'Jamaica',
        call_code: '+1-876',
        currency: 'JMD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: null
    },
    JO: {
        code: 'JO',
        country: 'Jordan',
        call_code: '+962',
        currency: 'JOD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ar',
        alternative_language_codes: null
    },
    KZ: {
        code: 'KZ',
        country: 'Kazakhstan',
        call_code: '+7',
        currency: 'KZT',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'kk',
        alternative_language_codes: ['ru']
    },
    KE: {
        code: 'KE',
        country: 'Kenya',
        call_code: '+254',
        currency: 'KES',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: ['sw']
    },
    KR: {
        code: 'KR',
        country: 'South Korea',
        call_code: '+82',
        currency: 'KRW',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ko',
        alternative_language_codes: null
    },
    KW: {
        code: 'KW',
        country: 'Kuwait',
        call_code: '+965',
        currency: 'KWD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ar',
        alternative_language_codes: null
    },
    LA: {
        code: 'LA',
        country: 'Laos',
        call_code: '+856',
        currency: 'LAK',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'lo',
        alternative_language_codes: null
    },
    MO: {
        code: 'MO',
        country: 'Macao',
        call_code: '+853',
        currency: 'MOP',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'zh',
        alternative_language_codes: ['pt']
    },
    MK: {
        code: 'MK',
        country: 'Macedonia',
        call_code: '+389',
        currency: 'MKD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'mk',
        alternative_language_codes: null
    },
    MG: {
        code: 'MG',
        country: 'Madagascar',
        call_code: '+261',
        currency: 'MGA',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'mg',
        alternative_language_codes: ['fr']
    },
    MU: {
        code: 'MU',
        country: 'Mauritius',
        call_code: '+230',
        currency: 'MUR',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: null
    },
    MD: {
        code: 'MD',
        country: 'Moldova',
        call_code: '+373',
        currency: 'MDL',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ro',
        alternative_language_codes: ['ru']
    },
    MN: {
        code: 'MN',
        country: 'Mongolia',
        call_code: '+976',
        currency: 'MNT',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'mn',
        alternative_language_codes: null
    },
    MA: {
        code: 'MA',
        country: 'Morocco',
        call_code: '+212',
        currency: 'MAD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ar',
        alternative_language_codes: ['fr']
    },
    MZ: {
        code: 'MZ',
        country: 'Mozambique',
        call_code: '+258',
        currency: 'MZN',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'pt',
        alternative_language_codes: null
    },
    NA: {
        code: 'NA',
        country: 'Namibia',
        call_code: '+264',
        currency: 'NAD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: ['af']
    },
    NE: {
        code: 'NE',
        country: 'Niger',
        call_code: '+227',
        currency: 'XOF',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'fr',
        alternative_language_codes: null
    },
    NG: {
        code: 'NG',
        country: 'Nigeria',
        call_code: '+234',
        currency: 'NGN',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: null
    },
    OM: {
        code: 'OM',
        country: 'Oman',
        call_code: '+968',
        currency: 'OMR',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ar',
        alternative_language_codes: null
    },
    PA: {
        code: 'PA',
        country: 'Panama',
        call_code: '+507',
        currency: 'PAB',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'es',
        alternative_language_codes: null
    },
    PY: {
        code: 'PY',
        country: 'Paraguay',
        call_code: '+595',
        currency: 'PYG',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'es',
        alternative_language_codes: ['gn']
    },
    PE: {
        code: 'PE',
        country: 'Peru',
        call_code: '+51',
        currency: 'PEN',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'es',
        alternative_language_codes: null
    },
    PH: {
        code: 'PH',
        country: 'Philippines',
        call_code: '+63',
        currency: 'PHP',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: ['tl']
    },
    QA: {
        code: 'QA',
        country: 'Qatar',
        call_code: '+974',
        currency: 'QAR',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ar',
        alternative_language_codes: null
    },
    RW: {
        code: 'RW',
        country: 'Rwanda',
        call_code: '+250',
        currency: 'RWF',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'rw',
        alternative_language_codes: ['fr', 'en']
    },
    LC: {
        code: 'LC',
        country: 'Saint Lucia',
        call_code: '+1-758',
        currency: 'XCD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: null
    },
    SA: {
        code: 'SA',
        country: 'Saudi Arabia',
        call_code: '+966',
        currency: 'SAR',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ar',
        alternative_language_codes: null
    },
    SN: {
        code: 'SN',
        country: 'Senegal',
        call_code: '+221',
        currency: 'XOF',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'fr',
        alternative_language_codes: null
    },
    ZA: {
        code: 'ZA',
        country: 'South Africa',
        call_code: '+27',
        currency: 'ZAR',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: ['af', 'zu', 'xh']
    },
    LK: {
        code: 'LK',
        country: 'Sri Lanka',
        call_code: '+94',
        currency: 'LKR',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'si',
        alternative_language_codes: ['ta']
    },
    TW: {
        code: 'TW',
        country: 'Taiwan',
        call_code: '+886',
        currency: 'TWD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'zh',
        alternative_language_codes: null
    },
    TZ: {
        code: 'TZ',
        country: 'Tanzania',
        call_code: '+255',
        currency: 'TZS',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'sw',
        alternative_language_codes: ['en']
    },
    TT: {
        code: 'TT',
        country: 'Trinidad and Tobago',
        call_code: '+1-868',
        currency: 'TTD',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'en',
        alternative_language_codes: null
    },
    TN: {
        code: 'TN',
        country: 'Tunisia',
        call_code: '+216',
        currency: 'TND',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'ar',
        alternative_language_codes: null
    },
    TR: {
        code: 'TR',
        country: 'Turkey',
        call_code: '+90',
        currency: 'TRY',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'tr',
        alternative_language_codes: null
    },
    UY: {
        code: 'UY',
        country: 'Uruguay',
        call_code: '+598',
        currency: 'UYU',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'es',
        alternative_language_codes: null
    },
    UZ: {
        code: 'UZ',
        country: 'Uzbekistan',
        call_code: '+998',
        currency: 'UZS',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'uz',
        alternative_language_codes: ['ru']
    },
    VN: {
        code: 'VN',
        country: 'Vietnam',
        call_code: '+84',
        currency: 'VND',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'vi',
        alternative_language_codes: null
    },
    AL: {
        code: 'AL',
        country: 'Albania',
        call_code: '+355',
        currency: 'ALL',
        service_agreement: 'recipient',
        capabilities: {
            card_payments: { requested: false },
            transfers: { requested: true }
        },
        platform: StripeRegions.US,
        stripe_terminal: false,
        language_code: 'sq',
        alternative_language_codes: null
    }
};

export function getCountryByCallCode(callCode: string): CountryDetails | undefined {
    return Object.values(countries).find(country => country.call_code === callCode);
}
