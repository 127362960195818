import { RequiredAddressFields } from '@airwallet/shared-models/stripe';

export const requiredAddressProperties: Record<string, RequiredAddressFields> = {
    Australia: {
        country: true,
        line1: true,
        city: true,
        state: true,
        postal_code: true
    },
    Canada: {
        country: true,
        line1: true,
        city: true,
        state: true,
        postal_code: true
    },
    Italy: {
        country: true,
        line1: true,
        city: true,
        state: true,
        postal_code: true
    },
    Spain: {
        country: true,
        line1: true,
        city: true,
        state: true,
        postal_code: true
    },
    'United States': {
        country: true,
        line1: true,
        city: true,
        state: true,
        postal_code: true
    },
    Austria: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    Belgium: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    'Czech Republic': {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    Denmark: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    Finland: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    France: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    Germany: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    Luxembourg: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    Malaysia: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    Netherlands: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    'New Zealand': {
        country: true,
        line1: true,
        city: true,
        state: true,
        postal_code: true
    },
    Norway: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    Portugal: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    Sweden: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    Switzerland: {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    'United Kingdom': {
        country: true,
        line1: true,
        city: true,
        state: false,
        postal_code: true
    },
    Ireland: {
        country: true,
        line1: true,
        city: false,
        state: false,
        postal_code: true
    },
    Singapore: {
        country: true,
        line1: true,
        city: false,
        state: false,
        postal_code: true
    }
};
